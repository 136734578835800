.PostContainer {
  position: relative;
}

.PostContainer .Content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.PostContainer:hover {
  cursor: pointer;
}

.PostContainer::after {
  content: '';
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  /* css tricks to make responsive square */
  padding-top: calc(100%);
}

.PostContainer:hover::after {
  background: rgba(0, 0, 0, 0.3);
}

.PostContainer:hover .Content:not(.Error) {
  z-index: -1;
}

.Img,
.Video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Video:fullscreen,
.PostModalContainer .Img,
.PostModalContainer .Video {
  object-fit: contain;
}

.PostModalContainer {
  display: flex;
  flex-direction: row;
  background-color: black;
}

.PostModalList {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 30vw;
  min-width: 20vw;
}

@media (max-width: 450px) {
  .PostModalContainer {
    flex-direction: column;
    width: 100%;
  }

  .PostModalContainer > * {
    align-self: center;
  }

  .PostModalList {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100% !important;
  }
}

.PostModal {
  display: flex;
}

.Badge {
  position: absolute;
  top: 0;
  right: 0;
}
