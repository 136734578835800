.App {
  --var-padding: 16%;
  --var-app-width: (100% - var(--var-padding) * 2);

  text-align: center;
  padding: 0 var(--var-padding);
}

@media (max-width: 700px) {
  .App {
    --var-padding: 8%;
  }
}

@media (max-width: 450px) {
  .App {
    --var-padding: 3%;
  }
}
