.Timeline {
  --var-columns: 3;
  --var-gap: 2rem;

  display: grid;
  grid-template-columns: repeat(var(--var-columns), 1fr);
  grid-gap: var(--var-gap);
  margin-bottom: 1rem;
}

@media (max-width: 700px) {
  .Timeline {
    --var-gap: 1rem;
  }
}

@media (max-width: 450px) {
  .Timeline {
    --var-gap: 0;
  }
}
